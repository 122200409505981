import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TimelineMax, Linear } from 'gsap'
import DefaultLayout from '../components/Layout/DefaultLayout'
import { CallBanner } from '../components/Banners'

const TestPage = () => {
  const tl = new TimelineMax({ repeat: -1, yoyo: true })

  useEffect(() => {
    tl.from('.test', 5, { scale: 2, ease: Linear.easeOut })
  }, [tl])

  return (
    <DefaultLayout>
      <CallBanner />
    </DefaultLayout>
  )
}
export default TestPage
